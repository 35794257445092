import React from 'react';
import JsonEditor from './components/JsonEditor';


function App() {
  return (
      <div className="App">
        <JsonEditor />
      </div>
  );
}

export default App;